import React from 'react';

import styles from './styles.module.scss';
import ScrollTop from '../../components/ScrollTop';

const NotFoundPage: React.FC = () => {
  return (
    <div className={styles.block}>
      <span className={styles.smiley}>
        :(
      </span>

      <p>
        Page not found
      </p>

      <ScrollTop />
    </div>
  );
};

export default NotFoundPage;