import React, { useEffect } from 'react';

import { Props } from './types';

const ScrollTop: React.FC<Props> = (props) => {
  const { history } = props;

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default ScrollTop;